import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
  standalone: true
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value?: string | unknown): SafeUrl {
    return value && typeof value === 'string' ? this.sanitizer.bypassSecurityTrustUrl(value) : '';
  }
}
